@tailwind base;

@tailwind components;

@tailwind utilities;

.btn {
	@apply py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md shadow-sm transition duration-150 ease-in-out;
}

.btn:focus {
	@apply outline-none;
}

.btn-indigo {
	@apply bg-indigo-600 text-white;
}
.btn-indigo:active {
	@apply bg-indigo-600;
}
.btn-indigo:hover {
	@apply bg-indigo-500;
}
.btn-indigo:focus {
	@apply bg-indigo-700 shadow-outline-blue;
}

.btn-gray {
	@apply bg-gray-400 text-gray-800;
}
.btn-gray:hover {
	@apply bg-gray-500;
}

.btn-cancel {
	@apply py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 transition duration-150 ease-in-out;
}
.btn-cancel:focus {
	@apply border-blue-300 shadow-outline-blue;
}
.btn-cancel:hover {
	@apply text-gray-500;
}
.btn-cancel:active {
	@apply bg-gray-50 text-gray-800;
}

.btn-warning {
	@apply inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 transition ease-in-out duration-150;
}
.btn-warning:hover {
	@apply bg-red-50;
}
.btn-warning:focus {
	@apply outline-none border-red-300 shadow-outline-red;
}
.btn-warning:active {
	@apply bg-red-200;
}

.thead {
	@apply px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider;
}

.td {
	@apply px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500;
}

.echarts {
	height: 100%;
	/* width: 100%; */
}

.activeTab {
	@apply whitespace-no-wrap ml-8 py-4 px-1 border-b-2 border-indigo-500 font-medium text-sm leading-5 text-indigo-600 !important;
}
.activeTab:focus {
	@apply outline-none text-indigo-800 border-indigo-700 !important;
}

.tab {
	@apply whitespace-no-wrap ml-8 py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500;
}

.tab:hover {
	@apply text-gray-700 border-gray-300;
}

.tab:focus {
	@apply outline-none text-gray-700 border-gray-300;
}
